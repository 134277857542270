<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
   

    <div class="b-example-divider"></div>

    <div class="container px-4 py-5" id="custom-cards">

        <div class="">
          <div
            class="card card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg"
            style="background-image: url('https://getbootstrap.com/docs/5.0/examples/features/unsplash-photo-3.jpg')"
          >
            <div
              class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1"
            >
              <h2 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                {{shop.name}}
              </h2>
              <ul class="d-flex list-unstyled mt-auto">
                <li class="me-auto">
                  <img
                    src="https://avatars.githubusercontent.com/u/2918581?v=4"
                    alt="Bootstrap"
                    width="32"
                    height="32"
                    class="rounded-circle border border-white"
                  />
                  {{shop.products.length}} Products
                </li>
                <li class="d-flex align-items-center me-3">
                  <svg class="bi me-2" width="1em" height="1em">
                    <use xlink:href="#geo-fill"></use>
                  </svg>
                  <small>{{shop.category.name}}</small>
                </li>
                <li class="d-flex align-items-center">
                  <svg class="bi me-2" width="1em" height="1em">
                    <use xlink:href="#calendar3"></use>
                  </svg>
                  <small>{{timeSince(new Date(shop.created_at))}} ago</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
    </div>

    <div class="b-example-divider"></div>

   <div class="container px-4 py-5" id="featured-3">
    <h2 class="pb-2 border-bottom">Location Details</h2>
    <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
      <div class="feature col">
        <div class="feature-icon bg-primary bg-gradient">
          <svg class="bi" width="1em" height="1em"><use xlink:href="#collection"></use></svg>
        </div>
        <h2>Address</h2>
        <p>{{shop.address.street}}.</p>
        <a href="#" class="icon-link">
          Call to action
          <svg class="bi" width="1em" height="1em"><use xlink:href="#chevron-right"></use></svg>
        </a>
      </div>
      <div class="feature col">
        <div class="feature-icon bg-primary bg-gradient">
          <svg class="bi" width="1em" height="1em"><use xlink:href="#people-circle"></use></svg>
        </div>
        <h2>Country</h2>
        <p>{{shop.address.country}}.</p>
        <a href="#" class="icon-link">
          Call to action
          <svg class="bi" width="1em" height="1em"><use xlink:href="#chevron-right"></use></svg>
        </a>
      </div>
      <div class="feature col">
        <div class="feature-icon bg-primary bg-gradient">
          <svg class="bi" width="1em" height="1em"><use xlink:href="#toggles2"></use></svg>
        </div>
        <h2>City</h2>
        <p>{{shop.address.city}}.</p>
        <a href="#" class="icon-link">
          Call to action
          <svg class="bi" width="1em" height="1em"><use xlink:href="#chevron-right"></use></svg>
        </a>
      </div>
    </div>
  </div>
  </main>
</template>

<script>
import global from "@/mixins/global.js";
import axios from "axios";
export default {
  mixins: [global],
  mounted() {
    this.getShop();
  },
};
</script>

<style>
.display-6{
    color: white;
}
</style>